import Layout from "../components/Layout";
import Page from "../components/Page";
import React from "react";
import Sidebar from "../components/Sidebar";
import Youtube from "../components/Youtube/Youtube";
import { graphql } from "gatsby";

const NotFoundTemplate = ({ data }) => {
  const { title, subtitle } = data.site.siteMetadata;

  return (
    <Layout title={`Not Found - ${title}`} description={subtitle}>
      <Sidebar />
      <Page title="Not Found">
        <p>
          We don't seem to have this topic covered yet.{" "}
          <a href="mailto:hello@pixeesoft.ch">Drop us a line</a> and we'll make
          it happen! 💪
        </p>
        <Youtube videoId="e8gxyzosgqQ" title="404 Not Found Pixeesoft video" />
        <p>
          And also follow us on{" "}
          <a href="https://www.facebook.com/pixeesoft">facebook</a>,{" "}
          <a href="https://twitter.com/pixeesoft">twitter</a>,{" "}
          <a href="https://www.instagram.com/pixeesoft/">instagram</a> or{" "}
          <a href="https://www.youtube.com/channel/UCg2y5wwYSWQnqMjYGI7QoEg">
            youtube
          </a>
          !
        </p>
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
`;

export default NotFoundTemplate;
