import React from "react";
import styles from "./Youtube.module.scss";

const Youtube = ({ videoId, title }) => {
  const videoUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className={styles["iframe-container"]}>
      <iframe
        className={styles["iframe-responsive"]}
        title={title}
        src={videoUrl}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullscreen
      />
    </div>
  );
};

export default Youtube;
